import React from 'react';
import LayoutIT from '../components/layoutIT';
import SEO from '../components/seo';
import AboutIT from '../containers/expositionIT';

type AboutPageProps = {};

const AboutPage: React.FunctionComponent<AboutPageProps> = () => {
  return (
    <LayoutIT>
      <SEO
        title="Mostre in corso"
        description="Mostra in corso nella nostra galleria SIFREIN di Parigi"
      />

      <AboutIT />
    </LayoutIT>
  );
};

export default AboutPage;
